import React, { FC, useEffect } from 'react';
import { PageProps } from 'gatsby';
import { Button, Seo } from '../../components';
import { PageLayout } from '../../components';
import config from '../../../app-config.json';
import { siteMetadata } from '../../../gatsby-config';
import * as s from '../../style.pages/index.style';
import { BodyWrap } from '../taher-scheme';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { logoutFromQardanPortal } from '../../reduxStore/slices/qardanPortalSlice';
import { useAppDispatch, useAppSelector } from '../../reduxStore/store';
import { screenWidths } from '../../theme/css-generators';
import { navigate } from 'gatsby-link';
import { LOGIN_PAGE_LINK } from './login';
import { AccountApp } from '../../components/qardan-hasana';
import { MetaDescription } from '../husain-scheme';

const PAGE_TITLE = config.pages.QARDAN_HASANA.title;

//////////////////////////////////  component ////////////////////////////////
const App: FC<RouteComponentProps> = () => {
    // const { authToken, fetchingStatus, error, userDetails, } = useAppSelector(s => s.qardanPortal);
    const isAuthenticated = useAppSelector(s => s.qardanPortal.isAuthenticated);
    const authToken = useAppSelector(s => s.qardanPortal.authToken);


    return (
        <>
            <Seo title={`${PAGE_TITLE} | ${siteMetadata.title}`} propMetaDescription={MetaDescription} />
            {isAuthenticated && authToken ?
                <AccountApp /> :
                <Default />
            }
        </>
    )
}

export default App



const Default: FC<{}> = () => {
    const isAuthenticated = useAppSelector(s => s.qardanPortal.isAuthenticated);

    useEffect(() => {
        if (!isAuthenticated) { navigate(LOGIN_PAGE_LINK) }
    }, [isAuthenticated])

    return (
        <PageLayout>
            <BodyWrap >
                <br />
                <Typography variant='h2' color='inherit' >{PAGE_TITLE}</Typography>
                <br />
                <br />

            </BodyWrap>
        </PageLayout>
    )
}