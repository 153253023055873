import React, { FC, useEffect, useState } from 'react';
import { BackDrop, PageLayout, Table, Button } from '../../';
import config from '../../../../app-config.json';
import { navigate } from 'gatsby';
import { BodyWrap } from "../../../style.pages/index.style";
import { Grid, Box, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/store';
import { LOGIN_PAGE_LINK } from '../../../pages/qardan-hasana/login';
import { screenWidths } from '../../../theme/css-generators';
import moment from 'moment';
import { ChangePasswordForm } from '../../../components/Form';
import { Props as ChangePasswordFormProps } from '../../../components/Form/ChangePassword';
import { Response } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';
import styled from 'styled-components';
import { formatNum, TransactionDescription } from '../../../helper';
import { getQardanApplicantInfo, getAllQardanInstallments, changeQardanApplicantPassword, logoutFromQardanPortal } from '../../../reduxStore/slices/qardanPortalSlice';
// import UpdateDetailsForm from './UpdateDetailsForm';
// import TempPassLoginScreen from './TempPassLoginScreen';
// import BankAccountsList from './BankAccountsList';

const PAGE_TITLE = config.pages.QARDAN_HASANA.title;
// const PAGE_PATH = config.pages.HUSAIN_SCHEME.routes.account.path;
const PARENT_PAGE_TITLE = config.pages.QARDAN_HASANA.title;
// const HS_EMAIL = config.pages.HUSAIN_SCHEME.details.email
// const PARENT_PAGE_PATH = config.pages.HUSAIN_SCHEME.path
// export const HS_ACCOUNT_PAGE_LINK = `${PARENT_PAGE_PATH}${PAGE_PATH}`;

interface Props {
    // authToken: string;
}

const AccountApp: FC<Props> = ({ }) => {
    return (
        <PageLayout>
            <BodyWrap>
                <Main />
            </BodyWrap>
        </PageLayout>
    )
}

export default AccountApp;


interface Props { }
const Main: FC<Props> = ({ }) => {
    const applicantDetails = useAppSelector(s => s.qardanPortal.applicantDetails);
    const authToken = useAppSelector(s => s.qardanPortal.authToken);
    const isLoggedInWithTempPassword = useAppSelector(s => s.qardanPortal.isLoggedInWithTempPassword);
    const installmentDetails = useAppSelector(s => s.qardanPortal.installmentDetails);
    const fetchingStatus = useAppSelector(s => s.qardanPortal.fetchingStatus);
    const widthUnder500 = useMediaQuery(`(min-width: ${screenWidths._500px})`);
    const widthUnder667 = useMediaQuery(`(min-width: 667px)`);
    const dispatch = useAppDispatch();
    // const isAuthenticated = useAppSelector(s => s.qardanPortal.isAuthenticated);

    const handleLogout = () => {
        dispatch(logoutFromQardanPortal())
    }

    const handleChangePassword: ChangePasswordFormProps['handleSubmit'] = async (values) => {
        const { err, res } = await new Promise<{ err?: any, res?: Response }>((resolve) => {
            dispatch(changeQardanApplicantPassword({
                oldPassword: values.oldPassword, newPassword: values.newConfirmPassword, callback: (err, res) => {
                    err && resolve({ err })
                    res && resolve({ res })
                }
            }))
        })

        if (res) {
            return { successMsg: res.message || "Password has been changed successfully" }
        } else {
            console.log("ChangePassword_Error", err);
            return { errorMsg: err.errors[0].message }
        }
    }

    const handleChangePage = (pageNumber: number, pageSize: number) => {
        pageNumber++
        const { filterOptions, } = installmentDetails;
        // console.log("pageNumber", pageNumber, "pageSize", pageSize)
        dispatch(getAllQardanInstallments({ ...filterOptions, pageNumber, pageSize }))
    }

    // useEffect(() => {
    //     if (!isAuthenticated) { navigate(LOGIN_PAGE_LINK) }
    // }, [isAuthenticated])
    useEffect(() => {
        if (applicantDetails && authToken) {
            dispatch(getAllQardanInstallments(installmentDetails.filterOptions))
            dispatch(getQardanApplicantInfo({ applicantId: applicantDetails.id }))
        }
    }, [])


    if (fetchingStatus.getAllQardanInstallments || !applicantDetails) {
        return <BackDrop open={true} >
            <Typography style={{ color: 'white' }} variant='h2' >Loading....</Typography>
        </BackDrop>
    }

    return (
        <Box>
            <Grid container justify='space-between' alignItems='center' >
                <Typography variant='h2' color='inherit' >{PAGE_TITLE} Portal</Typography>
                {widthUnder500 ?
                    <Button color='primary' onClick={handleLogout} >SIGN OUT</Button> :
                    <Button color='primary' size='small' onClick={handleLogout} >SIGN OUT</Button>
                }
            </Grid >

            {!widthUnder667 && <br />}
            <hr />
            <br />

            {/* this change password button is displayed here to change the temporary password */}
            {isLoggedInWithTempPassword &&
                <Grid container justify='flex-start' >
                    <ChangePasswordForm defaultOpen={true} handleSubmit={handleChangePassword} />
                </Grid>
            }


            {!isLoggedInWithTempPassword &&
                <>
                    <Typography gutterBottom variant='h6' component='p' ><b>Name:</b> {applicantDetails.title} {applicantDetails.first_name} {applicantDetails.last_name}</Typography>
                    <Typography gutterBottom variant='h6' component='p' ><b>ITS No:</b> {applicantDetails.its_no || <span style={{ color: 'grey' }}>Please update your ITS Number</span>}</Typography>
                    {applicantDetails.company_name &&
                        <Typography gutterBottom variant='h6' component='p' ><b>Company Name:</b> {applicantDetails.company_name}</Typography>}
                    <Typography gutterBottom variant='h6' component='p' ><b>Email:</b> {applicantDetails.email || <span style={{ color: 'grey' }}>Please update your email address</span>}</Typography>
                    <Typography gutterBottom variant='h6' component='p' ><b>Mobile No:</b> {applicantDetails.phone_no || <span style={{ color: 'grey' }}>Please update your mobile number</span>}</Typography>
                    <Grid container justify='flex-start' spacing={2} >
                        <Grid item><ChangePasswordForm handleSubmit={handleChangePassword} /></Grid>
                        {/* <Grid item><UpdateDetailsForm /></Grid> */}
                    </Grid>
                    <br /><br />
                    <Typography align='right' gutterBottom variant='h6' style={{ fontSize: '23px' }} component='p' ><b>File No:</b> {applicantDetails.id.toLocaleUpperCase()}</Typography>
                    <Typography align='right' gutterBottom variant='h6' style={{ fontSize: '23px' }} component='p' ><b>Qardan Amount:</b> £{formatNum(applicantDetails.loanAllocation?.amount) || 0}</Typography>
                    <Typography align='right' gutterBottom variant='h6' style={{ fontSize: '23px', color: 'green' }} component='p' ><b>Qardan Repaid:</b> £{formatNum(applicantDetails.loanRepaid) || 0}</Typography>

                    {
                        (applicantDetails.loanRepaid || 0) > (applicantDetails.loanAllocation?.amount || 0) ?
                            <Typography align='right' gutterBottom variant='h6' style={{ fontSize: '23px', color: "green" }} component='p' ><b>Balance:</b> £{formatNum(Math.abs((applicantDetails.loanRepaid || 0) - (applicantDetails.loanAllocation?.amount || 0)) || 0)}</Typography> :
                            <Typography align='right' gutterBottom variant='h6' style={{ fontSize: '23px', color: "red" }} component='p' ><b>Balance:</b> £{formatNum(Math.abs((applicantDetails.loanRepaid || 0) - (applicantDetails.loanAllocation?.amount || 0)) || 0)}</Typography>
                    }

                    <Typography align='right' gutterBottom variant='h6' style={{ fontSize: '23px' }} component='p' ><b>Repayment Start Date:</b> {moment(applicantDetails.loanAllocation?.repayment_date).add().format("DD MMM YY")}</Typography>
                    <Typography align='right' gutterBottom variant='h6' style={{ fontSize: '23px' }} component='p' ><b>Repayment End Date:</b> {moment(applicantDetails.loanAllocation?.repayment_date).add(applicantDetails.loanAllocation?.repayment_duration_in_months, 'month').format("DD MMM YY")}</Typography>

                    <br />

                    <Table
                        label={
                            <Typography variant='h4' align='center' >All Installments</Typography>
                        }
                        columns={[
                            // { id: "transaction_id", align: "left", label: "ID" }, // 01 Apr 21
                            { id: "date", align: "left", label: "Date", format: (val: string) => val.length > 24 ? val : moment(val).format("DD MMM YY") },
                            { id: "amount", align: "center", label: "Money in", format: (val: number) => val >= 0 ? <p style={{ color: 'green' }} >£{formatNum(val)}</p> : "" },
                            { id: "amount", align: "center", label: "Money out", format: (val: number) => val < 0 ? <p style={{ color: 'red' }} >£{formatNum(Math.abs(val))}</p> : "" },
                            { id: "description", align: "center", label: "Description", format: (val: number) => TransactionDescription[val] || val },
                        ]}
                        rows={installmentDetails.data || []}
                        totalRows={installmentDetails.totalCount}
                        pageNumber={installmentDetails.filterOptions.pageNumber! - 1}
                        onChangePageNo={handleChangePage}
                        onChangeRowsPerPage={handleChangePage}
                        dataFetchingAsync={true}
                        loading={fetchingStatus.getAllQardanInstallments}
                        emptyRowMsg={<Grid >
                            <br /><br /><br />
                            <Typography variant='h4' align='center' color='textSecondary' >There are no installments to display</Typography>
                            <br /><br /><br />
                        </Grid>}
                    />
                </>
            }


        </Box>
    )
}


const useStyles = makeStyles((theme) => ({
    container: {
        // backgroundColor: "whitesmoke",
        width: '100%',
        minHeight: "500px",
    }
}))